import React, { useState } from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'

const SubNavigation = ({ subMenus }) => (
  <ul className="navbar-nav nav-dropdown p-4 border shadow-lg rounded border-grey-6">
    {subMenus.map((submenu) => (
      <li className="nav-item py-2" key={submenu.url}>
        <Link to={submenu.url}>{submenu.name}</Link>
      </li>
    ))}
  </ul>
)

const Navigation = ({ pageURL = '/' }) => {
  const [isMobileMenuOpen, toggleMobileMenu] = useState(false)
  const data = useStaticQuery(graphql`
    query {
      allContentfulPageProductsModal {
        nodes {
          products {
            slug
            name
          }
        }
      }
      allContentfulGeneralMiscDetailsModal {
        nodes {
          logo {
            file {
              url
            }
          }
          pageHeaderBg {
            file {
              url
            }
          }
          shortDescription {
            shortDescription
          }
        }
      }
    }
  `)
  const { products } = data.allContentfulPageProductsModal.nodes[0]
  const {
    logo: {
      file: { url: logo },
    },
    shortDescription: { shortDescription },
    pageHeaderBg: {
      file: { url },
    },
  } = data.allContentfulGeneralMiscDetailsModal.nodes[0]
  const productsSubmenus = products.map((product) => ({
    url: `/products/${product.slug}`,
    name: product.name,
  }))

  return (
    <header id="top">
      <style
        dangerouslySetInnerHTML={{
          __html: ['header:before {', `background-image: url(${url});`, '}'].join('\n'),
        }}></style>
      <nav className="container font-weight-bold">
        <div className="row justify-content-between align-items-baseline pt-1">
          <div className="col-md-5 col-xs-12">
            <Link to="/">
              <img
                src={`https:${logo}`}
                className="responsive-img"
                alt="Kinetics Ventilations Equipments LLC"
              />
            </Link>
          </div>
          <div className="col-md-6 text-center text-uppercase text-grey-9 d-none d-md-block">
            <h4>{shortDescription}</h4>
          </div>
          <div className="col-md-6 d-sm-block d-md-none text-center px-0">
            <button
              className="btn btn-dark btn-block mt-4 border d-flex justify-content-start align-items-center border-top-thick"
              type="button"
              onClick={() => toggleMobileMenu(!isMobileMenuOpen)}>
              <span className={`${isMobileMenuOpen ? 'fas fa-times' : 'fas fa-bars'}`}></span>
              <p className="px-3 my-0">Main menu</p>
            </button>
          </div>
        </div>
        <div className="row pt-md-3 pt-0">
          <div
            className={`col-12 border border-right-0 border-left-0  border-bottom-0 ${
              !isMobileMenuOpen ? 'd-none d-md-block' : 'show background-white text-center'
            }`}>
            <ul
              className={`list-group justify-content-between ${
                !isMobileMenuOpen ? 'list-group-horizontal' : ''
              }`}>
              <li className="list-group-item border-0 background-trans">
                <Link
                  className={`${
                    pageURL === '/' ? `text-purple border-nav-active` : ''
                  } text-decoration-none border-nav`}
                  to="/">
                  <h6 className={`pb-2 mb-0`}>Home</h6>
                </Link>
              </li>
              <li className="list-group-item border-0 background-trans">
                <Link
                  className={`${
                    pageURL === '/about-us' ? `text-purple border-nav-active` : ''
                  } text-decoration-none border-nav`}
                  to="/about-us">
                  <h6 className={`pb-2 mb-0`}>About</h6>
                </Link>
              </li>
              <li className="list-group-item border-0 background-trans">
                <Link
                  className={`${
                    pageURL === '/certifications' ? `text-purple border-nav-active` : ''
                  } text-decoration-none border-nav`}
                  to="/certifications">
                  <h6 className={`pb-2 mb-0`}>Certifications</h6>
                </Link>
              </li>
              <li className="list-group-item border-0 background-trans">
                <Link
                  className={`${
                    pageURL === '/services' ? `text-purple border-nav-active` : ''
                  } text-decoration-none border-nav`}
                  to="/services">
                  <h6 className={`pb-2 mb-0`}>Services</h6>
                </Link>
              </li>
              <li className="list-group-item border-0 background-trans nav-dropdown-parent">
                <Link
                  className={`${
                    pageURL === '/products' ? `text-purple border-nav-active` : ''
                  } text-decoration-none border-nav`}
                  to="/products">
                  <h6 className={`pb-2 mb-0`}>Products</h6>
                </Link>
                <SubNavigation subMenus={productsSubmenus} />
              </li>
              <li className="list-group-item border-0 background-trans">
                <Link
                  className={`${
                    pageURL === '/projects' ? `text-purple border-nav-active` : ''
                  } text-decoration-none border-nav`}
                  to="/projects">
                  <h6 className={`pb-2 mb-0`}>Projects</h6>
                </Link>
              </li>
              <li className="list-group-item border-0 background-trans">
                <Link
                  className={`${
                    pageURL === '/clients' ? `text-purple border-nav-active` : ''
                  } text-decoration-none border-nav`}
                  to="/clients">
                  <h6 className={`pb-2 mb-0`}>Clients</h6>
                </Link>
              </li>
              <li className="list-group-item border-0 background-trans">
                <Link
                  className={`${
                    pageURL === '/contact-us' ? `text-purple border-nav-active` : ''
                  } text-decoration-none border-nav`}
                  to="/contact-us">
                  <h6 className={`pb-2 mb-0`}>Contact</h6>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  )
}

export default Navigation
