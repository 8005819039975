import React from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'
import { getYear, format } from 'date-fns'

export default () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulPageContactUsModal {
        nodes {
          address {
            address
          }
          openTime
          closingTime
          closedDays
          email
          telephone
          workingDays
        }
      }
      allContentfulGeneralMiscDetailsModal {
        nodes {
          shortDescription {
            shortDescription
          }
          companyName
          logo {
            file {
              url
            }
          }
          pageFooterBg {
            file {
              url
            }
          }
        }
      }
    }
  `)

  const {
    address: { address },
    email,
    telephone,
    workingDays,
    closedDays,
    openTime,
    closingTime,
  } = data.allContentfulPageContactUsModal.nodes[0]

  const {
    companyName,
    shortDescription: { shortDescription },
    pageFooterBg: {
      file: { url },
    },
    logo: {
      file: { url: logo },
    },
  } = data.allContentfulGeneralMiscDetailsModal.nodes[0]

  const inTimeObj = new Date(openTime)
  const outTimeObj = new Date(closingTime)
  const inTime = `${format(inTimeObj, 'h : mm a')}`
  const outTime = format(outTimeObj, 'h : mm a')
  const currentYear = getYear(new Date())

  return (
    <footer className="pt-4 text-grey-6">
      <style
        dangerouslySetInnerHTML={{
          __html: ['footer:before {', `background-image: url(${url});`, '}'].join('\n'),
        }}></style>
      <div className="container">
        <div className="row">
          <div className="col-md-4">
            <div className="logo-background" style={{ backgroundImage: `url(${logo})` }} />
            <p className="mt-2">{shortDescription}</p>
          </div>
          <div className="col-md-3 footer-address-block">
            <div className="widget address">
              <ul className="list-unstyled">
                <li className="media mb-3">
                  <i className="fas fa-map-marker-alt  mr-4" />
                  <p className="mb-0">{address}</p>
                </li>
                <li className="media mb-3">
                  <i className="fas fa-headset mr-4" />
                  <div className="d-flex flex-column">
                    {telephone.map((tel) => (
                      <p className="mb-0" key={tel}>
                        {tel}
                      </p>
                    ))}
                  </div>
                </li>
                <li className="media mb-3">
                  <i className="far fa-envelope mr-4" />
                  <p className="mb-0">{email}</p>
                </li>
                <li className="media mb-3">
                  <i className="far fa-clock mr-4" />
                  <p className="mb-0">
                    {workingDays} :{' '}
                    <strong>
                      {inTime} - {outTime}
                    </strong>
                    <br />
                    {closedDays} : <strong>Closed</strong>
                  </p>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-4 offset-md-1">
            <h6 className="text-grey-8">Quick links</h6>
            <div className="row">
              <div className="col-md-6">
                <ul className="nav flex-column primary-hover">
                  <li className="nav-item">
                    <Link className="nav-link pl-0 text-grey-7" to="/about-us">
                      About us
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link pl-0 text-grey-7" to="/contact-us">
                      Contact us
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link pl-0 text-grey-7" to="/products">
                      Products
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link pl-0 text-grey-7" to="/services">
                      Services
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link pl-0 text-grey-7" to="/projects">
                      Projects
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="col-md-6">
                <ul className="nav flex-column primary-hover">
                  <li className="nav-item">
                    <Link className="nav-link pl-0 text-grey-7" to="/clients">
                      Clients
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link pl-0 text-grey-7" to="/careers">
                      Careers
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link pl-0 text-grey-7" to="/certifications">
                      Certifications
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link pl-0 text-grey-7" to="/brouchers">
                      Brouchers
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link pl-0 text-grey-7" to="/terms-and-conditions">
                      Terms &amp; Conditions
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="divider mt-1" />
      <div className="py-0">
        <div className="container">
          <div className="d-md-flex justify-content-between align-items-center py-2 text-center text-md-left">
            <div>
              ©{currentYear} All Rights Reserved by <strong>{companyName}</strong>
            </div>
            <div>
              <small>Website is designed &amp; developed in U.A.E</small>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}
