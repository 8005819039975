import React from 'react'

import NavigationHeader from './navigation'
import Footer from './footer'
import ScrollToTop from './scrollToTop'

export default class Layout extends React.Component {
  componentDidMount() {
    if (typeof window !== 'undefined') {
      require('smooth-scroll/dist/smooth-scroll.polyfills')('a[href*="#top"]', {
        speed: 400,
        easing: 'linear',
      })
    }
  }

  render() {
    const { children, pageURL, shouldScrollExist = true } = this.props
    return (
      <div id="top">
        <NavigationHeader pageURL={pageURL} />
        <>{children}</>
        <ScrollToTop shouldScrollExist={shouldScrollExist} />
        <Footer />
      </div>
    )
  }
}
