import React from 'react'

export default ({ pageTitle = 'Untitled page' }) => {
  return (
    <div className="container-fluid page-header mb-5 mt-0">
      <div className="container">
        <div className="row">
          <div className="col-md-12 align-self-center">
            <h1 className="font-weight-bold text-white mtt-6 text-uppercase display-4">
              {pageTitle}
            </h1>
          </div>
        </div>
      </div>
    </div>
  )
}
