import React from 'react'

const getScrollOffset = (windowObject, documentObject) => {
  const supportPageOffset = windowObject.pageYOffset !== undefined
  const isCSS1Compatible = (documentObject.compatMode || '') === 'CSS1Compat'

  const offset = supportPageOffset
    ? window.pageYOffset
    : isCSS1Compatible
    ? documentObject.documentElement.scrollTop
    : documentObject.body.scrollTop

  return offset
}

export default class ScrollToTop extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isScrollVisible: false,
    }
  }

  onScrollChange = () => {
    if (typeof window !== 'undefined' && typeof document !== 'undefined') {
      const scollShouldAppearAt = 800
      const { isScrollVisible } = this.state
      const scrollingDistance = getScrollOffset(window, document)
      if (scrollingDistance >= scollShouldAppearAt && isScrollVisible === false) {
        this.setState({ isScrollVisible: true })
      } else if (scrollingDistance < scollShouldAppearAt && isScrollVisible === true) {
        this.setState({ isScrollVisible: false })
      }
    }
  }

  componentDidMount() {
    if (typeof window !== 'undefined') {
      // Check if scroll is required for the page, then add scroll listener
      if (this.props.shouldScrollExist) {
        window.addEventListener('scroll', this.onScrollChange)
      }
    }
  }

  componentWillUnmount() {
    if (typeof window !== 'undefined') {
      window.removeEventListener('scroll', this.onScrollChange)
    }
  }

  render() {
    return (
      <div className="d-flex justify-content-center">
        <a
          href="#top"
          data-scroll
          className={`btn btn-link text-decoration-none p-0 circle-of-scroll ${
            this.state.isScrollVisible ? 'show' : ''
          }`}
          title="Go to top">
          <i className="fas fa-chevron-up font-size-5" />
        </a>
      </div>
    )
  }
}
